import {useEffect, useRef, useState} from "react";
import {CustomButton, CustomButtonProject, CustomTextField, CustomTextFieldProject} from "./Home";
import {Box, CircularProgress, Grid, RadioGroup, styled, Typography} from "@mui/material";
import {
    CustomCheckbox,
    CustomFormControlLabel,
    CustomIcon,
    CustomCheckedIcon,
    CustomRadio,
    CustomRadioIcon, CustomCheckedRadioIcon, CustomSquareRadioIcon, CustomCheckedSquareRadioIcon
} from "../mui-styles/styles";

import projectStyle from './Project.module.css';
import axios from "axios";
import {firebase} from "../firebase/clientApp";

const HiddenInput = styled('input')({
    display: 'none',
});

const UploadContainer = styled('div')({
    display: 'flex',
    alignItems: 'center',
});

const API_URL = "https://moncler-contest-2024-api-226934379894.europe-west1.run.app"
//const API_URL = "https://nardo-dev-portatile-api.plesh.co"

function Project() {

    const [userData, setUserData] = useState(null);

    const [formData, setFormData] = useState({
        nome: '',
        cognome: '',
        email: '',
        companyArea: '',
        projectName: '',
        descriptionShort: '',
        descriptionExtended: '',
        file: null,
        fileName:'select file',
        privacy: false,
    });
    const [loading, setLoading] = useState(false);
    const [success, setSuccess] = useState(false);
    const [formErrors, setFormErrors] = useState({});

    const inputRef = useRef(null);

    const initializeData = (user) => {
        // Get user from firebase auth
        setLoading(true);
        setUserData(user);
        const firstName = user?.displayName?.split(' ')?.length > 1 ? user.displayName.split(' ')[0] : user.displayName;
        const lastName = user?.displayName?.split(' ')?.length > 1 ? user.displayName.split(' ')[1] : '';
        setFormData((prevData) => ({
            ...prevData,
            nome: firstName,
            cognome: lastName,
            email: user.email
        }));
        setLoading(false);
    }

    useEffect(() => {
        firebase.auth().onAuthStateChanged((user) => {
            if (user)
                initializeData(user)
        })
    }, [])

    // Gestisce il cambiamento per tutti i campi del form
    const handleChange = (event) => {
        const { name, value, type, checked } = event.target;
        setFormData((prevData) => ({
            ...prevData,
            [name]: type === 'checkbox' ? checked : value,
        }));

        if (formErrors[name]) {
            setFormErrors((prevErrors) => {
                const { [name]: removedError, ...rest } = prevErrors;
                return rest;
            });
        }
    };

    const handleFileChange = (event) => {
        const file = event.target.files[0];

        if (file) {
            const allowedTypes = ['application/pdf', 'application/vnd.openxmlformats-officedocument.presentationml.presentation'];
            if (!allowedTypes.includes(file.type)) {
                alert('Per favore, seleziona un file PDF o PPTX.');
                return;
            }

            const maxSize = 25 * 1024 * 1024; // 25MB in bytes
            if (file.size > maxSize) {
                alert('Il file supera la dimensione massima di 25MB.');
                return;
            }

            setFormData((prevData) => ({
                ...prevData,
                file: file,
                fileName: file.name
            }));

            if (formErrors.file) {
                setFormErrors((prevErrors) => {
                    const { file: removedError, ...rest } = prevErrors;
                    return rest;
                });
            }
        }
    };

    const handleClick = () => {
        inputRef.current.click();
    };

    const handleSubmit = (e) => {
        e.preventDefault();

        const errors = {};

        if (!formData.nome.trim()) errors.nome = 'Il nome è obbligatorio.';
        if (!formData.cognome.trim()) errors.cognome = 'Il cognome è obbligatorio.';
        if (!formData.email.trim()) errors.email = 'L\'email è obbligatoria.';
        if (!formData.companyArea) errors.companyArea = 'Seleziona un\'area aziendale.';
        if (!formData.projectName.trim()) errors.projectName = 'Il titolo del progetto è obbligatorio.';
        if (!formData.descriptionShort.trim()) errors.descriptionShort = 'La descrizione breve è obbligatoria.';
        if (!formData.file) errors.file = 'Carica un file.';
        if (!formData.privacy) errors.privacy = 'Devi acconsentire al trattamento dei dati.';

        setFormErrors(errors);

        if (Object.keys(errors).length > 0) {
            return;
        }

        setLoading(true);

        const formDataToSend = new FormData();
        formDataToSend.append('nome', formData.nome);
        formDataToSend.append('cognome', formData.cognome);
        formDataToSend.append('email', formData.email);
        formDataToSend.append('companyArea', formData.companyArea);
        formDataToSend.append('projectName', formData.projectName);
        formDataToSend.append('descriptionShort', formData.descriptionShort);
        formDataToSend.append('descriptionExtended', formData.descriptionExtended);
        formDataToSend.append('privacy', formData.privacy);
        formDataToSend.append('file', formData.file);

        axios.post(`${API_URL}/upload`, formDataToSend, {
            headers: {
                'Content-Type': 'multipart/form-data' // Impostazione dell'header per l'upload del file
            }
        })
            .then(response => {
                console.info('Form inviato con successo', response);
                setSuccess(true);
                setLoading(false);
            })
            .catch(error => {
                console.info('Errore durante l\'invio del form', error);
                setLoading(false);
            });

    }



    return(
        <div style={{
            display: 'flex',
            flexDirection: 'column',
            width: '100vw',
            alignItems: 'flex-start',
            justifyContent: 'flex-start',
            position: 'relative',
            overflowX:'hidden'
        }}>
            {success &&
                <div
                    style={{
                        display: 'flex',
                        flexDirection: 'column',
                        width: '100vw',
                        height: '100vh',
                        alignItems: 'center',
                        justifyContent: 'center',
                        position: 'relative'
                    }}>
                    <img src={'/images/moncler-logo-black.svg'} className={projectStyle.logoSuccess}
                         style={{position:'absolute', top:'10vh'}}
                         alt={'Moncler Logo'}/>
                    <h2 style={{fontWeight: '400', marginTop: '0', marginBottom: 0, textAlign: 'center'}}>
                        Thank you for submitting your project!
                    </h2>

                </div>
            }

            {
                !success &&
                <div className={projectStyle.contentContainer}
                     style={{
                         display: 'flex',
                         flexDirection: 'column',
                         width: '100vw',
                         alignItems: 'flex-start',
                         justifyContent: 'flex-start',
                         position: 'relative',
                         paddingTop: '16px',
                     }}>
                    <img src={'/images/moncler-logo-black.svg'} className={projectStyle.logo}
                         style={{marginBottom: '5vh', marginTop: '5vh'}}
                         alt={'Moncler Logo'}/>

                    <img src={'/images/project-img.png'} className={projectStyle.image}
                         style={{width: '50%', position: 'absolute', right: '-5vw', top: '15%'}}
                         alt={'Moncler Img'}/>

                    <div className={projectStyle.welcomeText}>
                        <h2 style={{fontWeight: '400', marginTop: '0', marginBottom: 0}}>WELCOME </h2>
                        <p style={{fontSize: '16px', marginTop: '1vh', fontWeight: '500'}}>
                            Submit your idea to improve the future of our company. <br/>
                            Your projects will remain anonymous to the jury.
                        </p>
                    </div>

                    <div className={projectStyle.formContainer}>
                        <form onSubmit={handleSubmit}>
                            <Grid container spacing={2} justifyContent="flex-start" alignItems="flex-start">
                                <Grid item xs={12} sm={6}>
                                    <CustomTextFieldProject
                                        name="nome"
                                        label=""
                                        type="text"
                                        placeholder={'*Name'}
                                        variant="outlined"
                                        value={formData.nome}
                                        error={!!formErrors.nome}
                                        helperText={formErrors.nome}
                                        onChange={handleChange}
                                    />
                                </Grid>
                                <Grid item xs={12} sm={6}>
                                    <CustomTextFieldProject
                                        name="cognome"
                                        label=""
                                        type="text"
                                        placeholder={'*Last Name'}
                                        variant="outlined"
                                        value={formData.cognome}
                                        error={!!formErrors.cognome}
                                        helperText={formErrors.cognome}
                                        onChange={handleChange}
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <CustomTextFieldProject
                                        disabled={userData?.email && userData?.email.length > 0}
                                        name="email"
                                        label=""
                                        type="email"
                                        placeholder={'*Email'}
                                        variant="outlined"
                                        value={formData.email}
                                        error={!!formErrors.email}
                                        helperText={formErrors.email}
                                        onChange={handleChange}
                                    />
                                </Grid>
                            </Grid>

                            <Box>
                                <h2 style={{fontWeight: '600', marginBottom: 0}}>* COMPANY AREA</h2>
                                <p style={{fontSize: '18px', marginTop: '1vh', fontWeight: '500'}}>
                                    Select the area of the company for which you want to submit your idea
                                </p>
                                <RadioGroup
                                    value={formData.companyArea}
                                    onChange={handleChange}
                                    row // Disporre le caselle in fila orizzontale
                                >
                                    <Grid container spacing={3} justifyContent="space-between" alignItems="center">
                                        {['Marketing', 'People', 'Sustainability', 'Digital', 'Retail', 'Supply chain', 'Product & Others'].map((label, index) => (
                                            <Grid item key={index}>
                                                <div style={{
                                                    display: 'flex',
                                                    flexDirection: 'column',
                                                    alignItems: 'flex-start'
                                                }}>
                                                <span style={{
                                                    fontFamily: "'MonclerGothic', sans-serif",
                                                    fontSize: '16px',
                                                    marginBottom: '1vh',
                                                    fontWeight: '500'
                                                }}>
                                                {label}
                                                </span>
                                                    <CustomRadio
                                                        icon={<CustomSquareRadioIcon/>}
                                                        checkedIcon={<CustomCheckedSquareRadioIcon/>}
                                                        value={label}
                                                        name="companyArea"
                                                    />
                                                </div>
                                            </Grid>
                                        ))}
                                    </Grid>
                                </RadioGroup>
                                {formErrors.companyArea && (
                                    <Typography style={{
                                        color: '#d6413f',
                                        marginTop: '8px',
                                        fontSize: '12px',
                                        marginLeft: '16px'
                                    }}>
                                        {formErrors.companyArea}
                                    </Typography>
                                )}
                            </Box>

                            <Box>
                                <h2 style={{fontWeight: '600', marginBottom: '1vh'}}>* PROJECT TITLE <span
                                    style={{fontSize: '14px', fontWeight: 'normal'}}> (Max 120 characters) </span></h2>
                                <CustomTextFieldProject
                                    name="projectName"
                                    label=""
                                    type="text"
                                    variant="outlined"
                                    value={formData.projectName}
                                    error={!!formErrors.projectName}
                                    helperText={formErrors.projectName}
                                    onChange={handleChange}
                                    inputProps={{maxLength: 120}}
                                >
                                </CustomTextFieldProject>
                            </Box>

                            <Box>
                                <h2 style={{fontWeight: '600', marginBottom: 0}}>* IDEA DESCRIPTION SHORT <span
                                    style={{fontSize: '14px', fontWeight: 'normal'}}> (Max 800 characters) </span></h2>
                                <p style={{fontSize: '18px', marginTop: '1vh', fontWeight: '500'}}>
                                    Briefly describe your idea to improve the future of our company.
                                </p>
                                <CustomTextFieldProject
                                    name="descriptionShort"
                                    label=""
                                    type="text"
                                    variant="outlined"
                                    multiline
                                    rows={2}
                                    value={formData.descriptionShort}
                                    error={!!formErrors.descriptionShort}
                                    helperText={formErrors.descriptionShort}
                                    onChange={handleChange}
                                    inputProps={{maxLength: 800}}
                                >
                                </CustomTextFieldProject>
                            </Box>

                            <Box>
                                <h2 style={{fontWeight: '600', marginBottom: 0}}>IDEA DESCRIPTION EXTENDED <span
                                    style={{fontSize: '14px', fontWeight: 'normal'}}> (Max 2000 characters) </span></h2>
                                <p style={{fontSize: '18px', marginTop: '1vh', fontWeight: '500'}}>
                                    Describe your idea in depth.
                                </p>
                                <CustomTextFieldProject
                                    name="descriptionExtended"
                                    label=""
                                    type="text"
                                    variant="outlined"
                                    multiline
                                    rows={3}
                                    value={formData.descriptionExtended}
                                    onChange={handleChange}
                                    inputProps={{maxLength: 2000}}
                                >
                                </CustomTextFieldProject>
                            </Box>

                            <Box>
                                <h2 style={{fontWeight: '600', marginBottom: '1vh'}}>* UPLOAD A FILE <span
                                    style={{fontSize: '14px', fontWeight: 'normal'}}> (PDF o PPTX Max 25MB) </span></h2>
                                <UploadContainer>
                                    <CustomTextFieldProject
                                        variant="outlined"
                                        value={formData.fileName}
                                        error={!!formErrors.file}
                                        helperText={formErrors.file}
                                        onClick={handleClick}
                                        InputProps={{
                                            readOnly: true,
                                            style: {cursor: 'pointer'},
                                        }}
                                    />
                                    <CustomButtonProject onClick={handleClick}
                                                         sx={!!formErrors.file ? {marginBottom: '22px'} : {marginBottom: '0px'}}>
                                        UPLOAD
                                    </CustomButtonProject>
                                    <HiddenInput
                                        ref={inputRef}
                                        type="file"
                                        accept=".pdf, .pptx"
                                        onChange={handleFileChange}
                                    />
                                </UploadContainer>
                            </Box>
                            <Box sx={{marginTop: '5vh'}}>
                                <CustomFormControlLabel
                                    control={
                                        <CustomCheckbox
                                            name='privacy'
                                            disableRipple
                                            color="default"
                                            checked={formData.privacy}
                                            onChange={handleChange}
                                            icon={<CustomIcon/>}
                                            checkedIcon={<CustomCheckedIcon/>}
                                        />
                                    }
                                    label={<span style={{marginLeft: '16px'}}>Acconsento al trattamento dati...</span>}
                                />
                                {formErrors.privacy && (
                                    <Typography style={{
                                        color: '#d6413f',
                                        marginTop: '8px',
                                        fontSize: '12px',
                                        marginLeft: '16px'
                                    }}>
                                        {formErrors.privacy}
                                    </Typography>
                                )}
                            </Box>

                            <Box className={projectStyle.buttonContainer} sx={{
                                display: 'flex',
                                justifyContent: 'center',
                                marginTop: '5vh',
                                marginBottom: '5vh'
                            }}>
                                {loading ?
                                    <CircularProgress color={'black'}></CircularProgress>
                                    :
                                    <CustomButtonProject type='submit'>
                                        SHARE YOUR IDEA
                                    </CustomButtonProject>}
                            </Box>


                        </form>

                    </div>
                </div>
            }


        </div>
    )
}

export default Project;
