import {Button, styled, TextField, Alert} from "@mui/material";
import {useState} from "react";
import {useNavigate} from "react-router-dom";
import {firebase} from "../firebase/clientApp";

export const CustomTextField = styled(TextField)(({theme}) => ({
    backgroundColor: '#fff',
    borderRadius: 0,
    width: '80%',
    height: '55px',
    '& .MuiInputBase-input': {
        fontFamily: "'MonclerGothic', sans-serif",
        fontSize: '16px',
    },
    '& .MuiInputLabel-root': {
        fontFamily: "'MonclerGothic', sans-serif",
        fontSize: '16px',
    },
    '& .MuiInputLabel-root.Mui-focused': {
        color: '#000000', // Colore della label quando il TextField è in focus
        visibility: 'hidden'
    },
    '& .MuiOutlinedInput-root': {
        borderRadius: 0,
        '& fieldset': {
            borderColor: 'rgba(0, 0, 0, 0.23)', // Colore bordo iniziale
        },
        '&:hover fieldset': {
            borderColor: '#000000', // Colore bordo al passaggio del mouse
            color: '#000000'
        },
        '&.Mui-focused fieldset': {
            borderColor: '#000000', // Colore bordo quando in focus
            color: '#000000'
        },
    },
}));

export const CustomTextFieldProject = styled(TextField)(({theme}) => ({
    backgroundColor: '#fff',
    borderRadius: 0,
    width: '100%',
    padding: 0,
    '& .MuiInputBase-input': {
        fontFamily: "'MonclerGothic', sans-serif",
        fontSize: '16px',
        padding: '8px',
        '&::placeholder': {
            color: '#000000', // Colore del testo del placeholder
            opacity: 1, // Assicurati che il colore venga applicato
            fontWeight: '600'
        },
    },
    '& .MuiInputLabel-root': {
        fontFamily: "'MonclerGothic', sans-serif",
        fontSize: '16px',
        padding: 0
    },
    '& .MuiInputLabel-root.Mui-focused': {
        color: '#000000', // Colore della label quando il TextField è in focus
        visibility: 'hidden',
        padding: 0
    },
    '& .MuiOutlinedInput-root': {
        borderRadius: 0,
        padding: 0,
        '& fieldset': {
            borderColor: 'rgba(0, 0, 0, 0.23)', // Colore bordo iniziale
        },
        '&:hover fieldset': {
            borderColor: '#000000', // Colore bordo al passaggio del mouse
            color: '#000000'
        },
        '&.Mui-focused fieldset': {
            borderColor: '#000000', // Colore bordo quando in focus
            color: '#000000'
        },
    },
}));

export const CustomButton = styled(Button)(({theme}) => ({
    backgroundColor: '#000000',
    color: '#fff',
    borderRadius: 0,
    width: 'fit-content',
    fontFamily: "'MonclerGothic', sans-serif",
    height: '55px'
}));

export const CustomButtonProject = styled(Button)(({theme}) => ({
    backgroundColor: '#000000',
    color: '#fff',
    borderRadius: 0,
    width: 'fit-content',
    fontFamily: "'MonclerGothic', sans-serif",
}));


function Home() {
    const [loading, setLoading] = useState(false)

    const [error, setError] = useState(false)

    const navigate = useNavigate();

    // const handleButtonClick = () => {
    //     navigate('/project');
    // };

    const handleAzureSignin = async () => {
        setLoading(true)
        setError(false)
        const provider = new firebase.auth.OAuthProvider('microsoft.com');
        provider.setCustomParameters({
            tenant: '046171b1-437d-469a-9d6c-979d483f4233'
        });
        await firebase.auth().signInWithPopup(provider).then(data => {
            setLoading(false)
            navigate('/project', {replace: true})
        })
            .catch((e) => {
                setError(true)
                setLoading(false)
            })
    }


    return (
        <div style={{
            display: 'flex',
            flexDirection: 'column',
            width: '100vw',
            height: '100vh',
            alignItems: 'center',
            justifyContent: 'flex-end',
            backgroundImage: 'url(/images/bkg.png)',
            backgroundSize: 'cover',
            overflow: 'hidden',
            position: 'relative'
        }}>

            <img src={'/images/moncler-logo.svg'} style={{width: '30vh', position: 'absolute', top: '25vh'}}
                 alt={'Moncler Logo'}/>

            <p style={{
                color: '#fff',
                fontWeight: '400',
                textAlign: 'center',
                fontSize: '3rem',
                marginBottom: 0,
                padding: '16px'
            }}>
                INNOVATION INCUBATOR
            </p>

            <p style={{
                color: '#fff',
                fontWeight: '300',
                fontSize: '18px',
                textAlign: 'center',
                margin: 0,
                marginBottom: '10vh'
            }}>
                The Moncler Group platform that collects your ideas <br/>and your plans to improve the future of our company.
            </p>

            {/*<p style={{color: '#fff', fontWeight: '400', fontSize: '1rem', textAlign: 'center'}}>
                INSERISCI LA TUA MAIL E PARTECIPA
            </p>

            <div style={{display:'flex', flexDirection:'row', width:'350px', padding:'16px', marginBottom:'6vh'}}>
                <CustomTextField
                    label="MAIL"
                    type="email"
                    variant="outlined"
                    value={email}
                    onChange={handleEmailChange}
                />
                <CustomButton>Partecipa</CustomButton>
            </div>*/}

            <div style={{display: 'flex', flexDirection: 'column', alignItems: 'center', width: '100%', padding: '16px', marginBottom: '10vh'}}>
                {error && <Alert severity="error" variant="outlined" sx={{ mb: 2, color: 'error.light' }}>
                    Login failed, please try again or contact us if the problem persists.
                </Alert>}

                <CustomButton sx={{
                    fontSize: '16px',
                    fontWeight: '300',
                    height: 'fit-content',
                    width: '150px',
                    color: '#fff'
                }} onClick={handleAzureSignin} disabled={loading}>
                    JOIN NOW
                </CustomButton>
            </div>

        </div>
    )
}

export default Home;
