import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Home from "./pages/Home";
import Project from "./pages/Project";
import ProtectedRoute from "./components/protectedRoute";

function App() {
    return (
        <Router>
            <Routes>
                <Route path="/" element={<Home />} />
                <Route path="/home" element={<Home />} />
                <Route element={<ProtectedRoute />}>
                    <Route path="/project" element={<Project />} />
                </Route>
            </Routes>
        </Router>
    );
}

export default App;
