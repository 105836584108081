// Icona per la checkbox non selezionata
import {Checkbox, FormControlLabel, Radio, styled} from "@mui/material";

export const CustomIcon = styled('span')(({ theme }) => ({
    borderRadius: 2,
    width: 20,
    height: 20,
    border: '1px solid #000',
    backgroundColor: '#fff',
}));

// Icona per la checkbox selezionata
export const CustomCheckedIcon = styled(CustomIcon)({
    backgroundColor: '#000',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    '&:before': {
        content: '""',
        width: 12,
        height: 12,
        backgroundImage:
            "url(\"data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='%23FFF' viewBox='0 0 24 24'%3E%3Cpath d='M20.29 5.71a1 1 0 00-1.42 0L9 15.59l-3.29-3.3a1 1 0 00-1.42 1.42l4 4a1 1 0 001.42 0l10.3-10.3a1 1 0 000-1.42z'/%3E%3C/svg%3E\")",
        backgroundRepeat: 'no-repeat',
        backgroundPosition: 'center',
    },
});

// Stile per la checkbox personalizzata
export const CustomCheckbox = styled(Checkbox)(({ theme }) => ({
    '&:hover': {
        backgroundColor: 'transparent',
    },
    padding: 0,
}));

// Stile per la label con font personalizzato
export const CustomFormControlLabel = styled(FormControlLabel)(({ theme }) => ({
    fontFamily: "'MonclerGothic', sans-serif", // Imposta il fontFamily specifico
    marginLeft: 0,
    '& .MuiTypography-root': {
        fontFamily: "'MonclerGothic', sans-serif", // Applica il font anche al testo della label
        fontSize: '16px',
    },
}));

// Icona per il Radio Button non selezionato (quadrato)
export const CustomSquareRadioIcon = styled('span')(({ theme }) => ({
    borderRadius: 2,
    width: 20,
    height: 20,
    border: '1px solid #000',
    backgroundColor: '#fff',
}));

// Icona per il Radio Button selezionato (quadrato)
export const CustomCheckedSquareRadioIcon = styled(CustomSquareRadioIcon)({
    backgroundColor: '#000',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    '&:before': {
        content: '""',
        width: 12,
        height: 12,
        backgroundColor: '#fff',
    },
});

// Stile per il Radio Button personalizzato
export const CustomRadio = styled(Radio)(({ theme }) => ({
    '&:hover': {
        backgroundColor: 'transparent',
    },
    padding: 0,
}));

// Stile per la label del Radio Button
export const CustomRadioLabel = styled(FormControlLabel)(({ theme }) => ({
    fontFamily: "'MonclerGothic', sans-serif",
    marginLeft: 0,
    fontWeight:'500',
    '& .MuiTypography-root': {
        fontFamily: "'MonclerGothic', sans-serif",
        fontSize: '16px',
        fontWeight:'500'
    },
}));