import firebase from 'firebase/compat/app'
import 'firebase/compat/database'
import 'firebase/compat/firestore'
import 'firebase/compat/auth'

// Your web app's Firebase configuration
const firebaseConfig = {
    apiKey: "AIzaSyAptvqWzCsUVdQlrDm-h690-O0qtH_wDb4",
    authDomain: "moncler-contest-2024.firebaseapp.com",
    projectId: "moncler-contest-2024",
    storageBucket: "moncler-contest-2024.firebasestorage.app",
    messagingSenderId: "226934379894",
    appId: "1:226934379894:web:096d5b6c1d63459f8ed657"
};

// Initialize Firebase
firebase.initializeApp(firebaseConfig)
const auth = firebase.auth()

export {auth, firebase}
